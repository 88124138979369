import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CompanyAnalysis from './components/CompanyAnalysis';
import { Container, AppBar, Toolbar, Typography, Box } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff6b35',
    },
    secondary: {
      main: '#1a1a1a',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#1a1a1a',
      secondary: '#4a4a4a',
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '2rem',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '0.75rem 1.5rem',
          textTransform: 'none',
        },
        contained: {
          color: '#ffffff',
          '&:hover': {
            color: '#ffffff',
          },
          '&.Mui-disabled': {
            color: '#ffffff',
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '36px !important',
          padding: '0 24px !important',
          '@media (min-width: 600px)': {
            minHeight: '36px !important',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#1a1a1a',
          boxShadow: 'none',
          borderBottom: '1px solid #e0e0e0',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: 800,
          fontSize: '1.4rem',
          letterSpacing: '0.02em',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
            <img 
              src="/logo.png" 
              alt="Logo" 
              style={{ 
                height: '42px',
                width: 'auto',
                marginRight: '12px'
              }} 
            />
            <Typography variant="h6" component="h1" sx={{ 
              color: '#1a1a1a',
              '& span': {
                color: '#ff6b35'
              }
            }}>
              Why use <span>AI</span>?!
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <CompanyAnalysis />
      </Container>
    </ThemeProvider>
  );
}

export default App; 